<template>
  <EuiDialog :open="open" @onClose="$emit('change', false)">
    <div class="eui-u-mx-6 eui-u-mb-8">
      <div class="eui-u-flex eui-u-flex-col eui-u-items-center">
        <img class="es-login__logo" src="/logo_blue.svg" alt="logo eDocSign">
        <EuiHeading :level="2" bold class="eui-u-text-blue-grey">{{ $t('selectDomainDialog.selectDomain') }}</EuiHeading>
      </div>
      <EuiTextField adaptSize class="eui-u-mb-5">
        <EuiLabel>{{ $t('button.select') }}</EuiLabel>
        <select class="eui-a-input"
                v-model="selectedDomain">
          <option v-for="domain in domains"
                  :value="domain"
                  :key="domain.domainId">
            {{ domain.name }}
          </option>
        </select>
      </EuiTextField>
      <EuiButton color="primary"
                 size="small"
                 @click="setDomain"
                 :disabled="selectedDomain === undefined"
                 variant="raised">
        {{ $t('button.validate') }}
      </EuiButton>
    </div>
    <template #footer>
      <div class="eui-u-flex eui-u-justify-center eui-u-items-center">
        <div class="eui-u-flex-1"></div>
        <p class="eui-u-text-center eui-u-flex-1 eui-u-my-0 eui-u-text-blue-grey eui-u-text-base" v-html="$t('login.copyright')"></p>
        <EuiButton class="eui-u-flex-1 eui-u-px-3 eui-u-self-end eui-u-text-2xl" variant="text" color="primary" @click="goToHelp">
          <EuiIcon color="primary" size="medium" name="help"/>
          <span class="eui-u-ml-2">{{ $t('button.help') }}</span>
        </EuiButton>
      </div>
    </template>
  </EuiDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SelectDomainDialog',
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    open(isOpening) {
      if (isOpening) {
        this.selectedDomain = JSON.parse(JSON.stringify(this.$store.state.ModuleEdocSign.auth.domains.current));
      }
    },
  },
  data() {
    return {
      selectedDomain: undefined,
    };
  },
  computed: {
    ...mapState({
      domains: (state) => state.ModuleEdocSign.auth.domains.available,
      routeName: (state) => state.route.name,
    }),
  },
  methods: {
    ...mapActions({
      setCurrentDomain: 'ModuleEdocSign/auth/setCurrentDomain',
    }),
    setDomain() {
      if (this.selectedDomain) {
        this.setCurrentDomain(this.selectedDomain)
          .then(() => {
            if (this.routeName === 'login') {
              this.$emit('change', false);
              this.$router.push({ name: 'dashboard' });
            } else {
              this.$router.go();
            }
          });
      } else {
        this.$emit('change', false);
      }
    },
    goToHelp() {
      window.open(this.$t('button.help.link'), '_blank').focus();
    },
  },
};
</script>
